import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

import { useStorage, STORAGE_KEYS } from '@hooks/useStorage'
import { createCanBoundTo } from '@casl/react';
import ability, { updateAbility } from '@src/configs/acl/ability';

const UserContext = createContext(null);

export const UserConsumer = UserContext.Consumer

export const useUserContext = () => useContext(UserContext)

export const Can = createCanBoundTo(ability)

export const UserProvider = ({ children }) => {
  // ** Hooks
  const [userStorage, setUserStorage] = useStorage(STORAGE_KEYS.USER_DATA)

  // ** State
  const [user, setUser] = useState(userStorage)

  // ** Callback methods
  const updateUser = useCallback((newUser) => {
    if (!newUser) return
    
    setUserStorage(newUser)
    setUser(newUser)
    updateAbility(newUser)
  }, [])

  useEffect(() => {
    updateAbility(user)
  }, [user])

  return <UserContext.Provider value={{ user, updateUser, Can, ability }}>{children}</UserContext.Provider>
}
