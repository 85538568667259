// ** React Imports
import { useEffect, useState, createContext } from 'react'

import { useUserContext } from '@context/User'

// ** Create Context
const CustomerContext = createContext()

const CustomerProvider = ({ children }) => {
  const { user } = useUserContext()

  // ** State
  const [customer, setCustomer] = useState()

  useEffect(() => {
    if (!customer && user) {
      setCustomer({ value: user.customer_id, label: user.customer_name })
    }
  }, [customer, user])

  return <CustomerContext.Provider value={{ customer, setCustomer }}>{children}</CustomerContext.Provider>
}

export { CustomerContext, CustomerProvider }
