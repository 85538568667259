import { useState } from 'react';

export const useStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = async value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage

      await window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  }

  const clearValue = async () => {
    try {
      await window.localStorage.removeItem(key)
      setStoredValue()
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue, clearValue];
}

export const STORAGE_KEYS = {
  CUSTOMER_VIEW: 'CUSTOMER_VIEW',
  REDIRECT_URI: 'REDIRECT_URI',
  USER_DATA: 'USER_DATA',
  USER_TIMEZONE: 'USER_TIMEZONE',
}