import { AbilityBuilder, Ability } from '@casl/ability';

const ability = new Ability([]) 

const parsePermissions = (permission = []) => {
  /// TODO: Determine how to parse conditionals
  /// TODO: Validate parsed object
  // make assumption that permission is `.` deliminated with action as last item in array
  const subject = permission.split('.')
  const action = subject.pop()
  
  return [action, subject.join('.')]
}

export const updateAbility = (user) => {
  const { can, rules } = new AbilityBuilder(Ability)

  user?.permissions
    ?.map(parsePermissions)
    ?.forEach(([action, subject]) => can(action, subject))

  // Unitas User
  //if (user?.customer_name?.includes('Unitas')) {
    // Enable user admin - will revisit user admin later
    // can('list', 'users')

    // Enable UI customizer for UI ideation - disabled in config
    // can('modify', 'customizer')
  //}

  ability.update(rules);
}

export const ABILITIES = {
  LIST_SERVICE_ATTRIBUTES: 'service.attributes.list',
  GET_SERVICE_METRICS: 'service.metrics.get',
  LIST_SUPPORT_CASES: 'support.case.list',
  GET_BILLING_INVOICE: 'billing.invoice.get',
  LIST_BILLING_INVOICE: 'billing.invoice.list',
  LIST_CUSTOMER_ACCOUNTS: 'admin.accounts.list',
  VIEW_UNREGISTERED_LANDINGPAGE: 'unregistereduser.landingpage.view',

  // LIST_USERS: 'users.list',
  // MODIFY_CUSTOMIZER: 'customizer.modify',
}

export default ability