const { 
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_KNOWN_AUTHORITIES,
  REACT_APP_AUTH_SCOPES,
} = process.env

export const msalConfig = {
  auth: {
    clientId: REACT_APP_AUTH_CLIENT_ID,
    //For Azure AD B2C with a user flow use:
    authority: REACT_APP_AUTH_AUTHORITY,
    knownAuthorities: JSON.parse(REACT_APP_AUTH_KNOWN_AUTHORITIES),
    redirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: "localStorage",
  }
};

export const profileEditAuthority = "https://unitasportal.b2clogin.com/unitasportal.onmicrosoft.com/B2C_1A_PROFILEEDIT";

export const loginRequest = {
  scopes: JSON.parse(REACT_APP_AUTH_SCOPES),
};
