// ** React Imports
import ReactDOM from 'react-dom'
import { Suspense, lazy } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

// ** Redux Imports
import { store } from './redux/store'
import { Provider } from 'react-redux'

// ** Intl, CASL & ThemeColors Context
import { ThemeContext } from './utility/context/ThemeColors'
import { CustomerProvider } from './utility/context/Customer'
import { UserProvider } from './utility/context/User'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** MSAL Auth
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "@src/auth/config";

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const msalInstance = new PublicClientApplication(msalConfig)
msalInstance.enableAccountStorageEvents()

// ** Debugging process enviornment variables
window.processEnv = process.env

/// TODO: Consolidate Msal, User, Ability, and Customer Contexts
ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <UserProvider>
      <Router>
        <Provider store={store}>
          <Suspense fallback={<Spinner />}>
            <ThemeContext>
              <CustomerProvider>
                <LazyApp />
                <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
              </CustomerProvider>
            </ThemeContext>
          </Suspense>
        </Provider>
      </Router>
    </UserProvider>
  </MsalProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
